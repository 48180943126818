import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "../../sections/HeroSection/HeroSection.module.css";
import { KeyboardArrowDown, PlayCircleOutline } from "@material-ui/icons";
import ReactPlayer from "react-player/youtube";
import { scrollToId } from "../../sections/NavBar/NavBar";

import logo from "../../assets/logo.svg";
import Button from "../../components/Button/Button";
import useToggle from "../../useToggle";
import Chip from "../../components/Chip/Chip";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const cx = classNames.bind(styles);

const HeroSection = (props) => {
  const [videoPlaying, toggleVideoPlaying] = useToggle(false);
  const [videoStarted, setVideoStarted] = useState(false);

  return (
    <div className={cx("base")}>
      <div className={cx("hero-slide")}>
        <div className={cx("video-wrapper")}>
          <div
            className={cx("video-overlay", { hide: videoPlaying })}
            onClick={() => {
              toggleVideoPlaying();
              if (!videoStarted) {
                setVideoStarted(true);
              }
            }}
          >
            {videoStarted && <PlayCircleOutline />}
          </div>
          <ReactPlayer
            playing={videoPlaying}
            onPause={toggleVideoPlaying}
            width="100%"
            height={450}
            url="https://www.youtube.com/watch?v=Iq45k4y5Wkk&t=1s&ab_channel=IMPACTNederland"
          />
        </div>

        <h1>Impact in the Netherlands!</h1>
      </div>
      <div className={cx("hero-box")}>
        <Chip className={"mb-8"}>UPDATE</Chip>
        <h2>IMPACT Netherlands 2024</h2>
        <h3>
          <strong>
            July 22<sup>nd</sup> – 27<sup>th</sup> 2024 🎉
          </strong>
        </h3>
        <h4>Vlissingen, Netherlands</h4>
        <div className={cx("buttons")}>
          {/* <Button big red href="https://registerseat.com/at/impact-nl-2024">
            Register now
          </Button> */}
          <Button big outline onClick={() => scrollToId("about")}>
            Learn more
          </Button>
        </div>
      </div>

      <div className={cx("hero-footer")}>
        <span className={cx("slogan")}>
          <strong>I</strong>nspiring <strong>M</strong>embers to{" "}
          <strong>P</strong>roclaim the <strong>A</strong>dvent of{" "}
          <strong>C</strong>hrist <strong>T</strong>ogether
        </span>
        <img src={logo} alt="" />
        <span className={cx("arrow")}>
          <KeyboardArrowDown fontSize={"large"} />
        </span>
      </div>
    </div>
  );
};

export default HeroSection;
